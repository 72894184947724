import { match as RouterMatch, RouteProps } from 'react-router';

import { fetchSSRSearch } from '~/resources/useSearch';
import DonateUnsubscribeConfirmationScreen from '~/screens/DonateUnsubscribeConfirmationScreen';
import { PostType } from '~/resources/PostType';
import GuidesScreen from '~/screens/GuidesScreen';
import {
  HomeScreen, SearchScreen, AboutScreen, PrivacyPolicyScreen, PublicOfferScreen,
  PublicOfferSubscriptionScreen, PostScreen, SuperPostScreen, ReportsScreen,
  ReportScreen, NotFoundScreen, RubricScreen, TopicScreen, DonateScreen, DonateUnsubscribeScreen,
  TagScreen, ProfileScreen, GiftScreen, VerifyScreen, VideoScreen, ChangepasswordScreen,
  AuthorScreen, AuthLoadingScreen, AdsScreen,
} from './screens';
import { fetchSSRPosts } from './resources/usePosts';
import { fetchSSRConfig } from './resources';
import { fetchSSRPost } from './resources/usePost';
import Auth from './resources/Auth';
import { fetchSSRReports } from './resources/useReports';
import { fetchSSRReport } from './resources/useReport';
import { fetchSSRRubric } from './resources/useRubric';
import { fetchSSRTag } from './resources/useTag';
import { fetchSSRNews } from './resources/useNews';
import { fetchSSRRecommends } from './resources/useRecommends';
import { fetchSSRTags } from './resources/useTags';
// import { fetchSSRDailyListPosts } from './resources/useDailyListPosts';
// import FriendsScreen from './screens/FriendsScreen';
import { fetchSSRSubscriptionReviews } from './resources/useSubscriptionReviews';
import SubscriptionPublicScreen from './screens/SubscriptionPublicScreen';
import { fetchSSRSubscriptionGiftPlan } from './resources/useGiftSubscription';
import { fetchSSRAuthor } from './resources/useAuthor';

type Match<T extends { [key: string]: string | undefined }
  = { [key: string]: string | undefined }> = RouterMatch<T>;

interface Route extends RouteProps {
  loadData?: (auth: Auth, url: string, match: Match, opts?: RequestInit) => Promise<number|void>
}

const routes: Route[] = [
  {
    path: '/',
    exact: true,
    component: HomeScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth, opts),
        fetchSSRPosts(auth, url, [PostType.ARTICLE, PostType.GUIDE], match, opts, 'main_page'),
        // // fetchSSRPosts(auth, url, PostType.LONGREAD, match, opts),
        fetchSSRNews(auth, opts),
        fetchSSRTags(auth, opts),
        // fetchSSRDailyListPosts(auth, opts),
      ]);
    },
  },
  {
    path: '/guides',
    exact: true,
    component: GuidesScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth, opts),
        fetchSSRPosts(auth, url, [PostType.GUIDE], match, opts),
      ]);
    },
  },
  {
    path: '/topic/:topic',
    exact: true,
    component: TopicScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth, opts),
        fetchSSRPosts(auth, url, [PostType.ARTICLE, PostType.GUIDE], match, opts),
        // fetchSSRPosts(auth, url, PostType.LONGREAD, match, opts),
      ]);
    },
  },
  {
    path: '/rubric/:rubric',
    exact: true,
    component: RubricScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth, opts),
        fetchSSRRubric(auth, url, match, opts),
        fetchSSRPosts(auth, url, [PostType.ARTICLE, PostType.GUIDE], match, opts),
      ]);
    },
  },
  {
    path: '/tag/:tag',
    exact: true,
    component: TagScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth, opts),
        fetchSSRTag(auth, url, match, opts),
        fetchSSRPosts(auth, url, [PostType.ARTICLE, PostType.GUIDE], match, opts),
      ]);
    },
  },
  {
    path: '/reports',
    exact: true,
    component: ReportsScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth);
      await fetchSSRReports(auth, url, opts);
    },
  },
  // {
  //   path: '/subscription-step',
  //   exact: true,
  //   component: SubscriptionScreen,
  //   loadData: async (
  //     auth: Auth, url: string, match: Match, opts: RequestInit = {},
  //   ): Promise<void> => {
  //     await fetchSSRConfig(auth);
  //   },
  // },
  {
    path: '/subscription',
    exact: true,
    component: SubscriptionPublicScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth),
        fetchSSRSubscriptionReviews(auth),
        // fetchSSRSubscriptionPlans(auth),
      ]);
    },
  },
  {
    path: '/subscription/:discountPromocode',
    exact: true,
    component: SubscriptionPublicScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth),
        fetchSSRSubscriptionReviews(auth),
        // fetchSSRSubscriptionPlans(auth),
      ]);
    },
  },
  {
    path: '/profile',
    exact: true,
    component: ProfileScreen,
  },
  {
    path: '/report/:slug',
    exact: true,
    component: ReportScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth);
      await fetchSSRReport(auth, match as Match<{slug: string}>, opts);
    },
  },
  {
    path: '/post/:slug',
    exact: true,
    component: PostScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
      const postId = await fetchSSRPost(auth, url, match as Match<{ slug: string}>, opts);
      await fetchSSRRecommends(auth, postId);
    },
  },
  {
    path: '/super/:slug',
    exact: true,
    component: SuperPostScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
      await fetchSSRPost(auth, url, match as Match<{ slug: string}>, opts);
    },
  },
  {
    path: '/longread/:slug',
    exact: true,
    component: SuperPostScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
      await fetchSSRPost(auth, url, match as Match<{ slug: string}>, opts);
    },
  },
  // {
  //   path: '/auth',
  //   exact: true,
  //   component: AuthScreen,
  //   loadData: async (
  //     auth: Auth, url: string, match: Match, opts: RequestInit = {},
  //   ): Promise<void> => {
  //     await fetchSSRConfig(auth, opts);
  //   },
  // },
  {
    path: '/auth/loading',
    exact: true,
    component: AuthLoadingScreen,
  },
  {
    path: '/verify/:key',
    exact: true,
    component: VerifyScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/changepassword/:key',
    exact: true,
    component: ChangepasswordScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/search',
    exact: true,
    component: SearchScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
      await fetchSSRSearch(auth, url, opts);
      // await fetchSSRPosts(auth, url, 'article');
    },
  },
  {
    path: '/video',
    exact: true,
    component: VideoScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/about',
    exact: true,
    component: AboutScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/ads',
    exact: true,
    component: AdsScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  // {
  //   path: '/friends',
  //   exact: true,
  //   component: FriendsScreen,
  //   loadData: async (
  //     auth: Auth, url: string, match: Match, opts: RequestInit = {},
  //   ): Promise<void> => {
  //     await fetchSSRConfig(auth, opts);
  //   },
  // },
  {
    path: '/donate',
    exact: true,
    component: DonateScreen,
  },
  {
    path: '/donate/unsubscribe',
    exact: true,
    component: DonateUnsubscribeScreen,
  },
  {
    path: '/donate/unsubscribe-profile',
    exact: true,
    component: DonateUnsubscribeConfirmationScreen,
  },
  {
    path: '/privacy_policy',
    exact: true,
    component: PrivacyPolicyScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/public_offer',
    exact: true,
    component: PublicOfferScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/public_offer_subscription',
    exact: true,
    component: PublicOfferSubscriptionScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
    },
  },
  {
    path: '/gift',
    exact: true,
    component: GiftScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await fetchSSRConfig(auth, opts);
      await fetchSSRSubscriptionGiftPlan(auth);
    },
  },
  {
    path: '/author/:authorId',
    exact: true,
    component: AuthorScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<void> => {
      await Promise.all([
        fetchSSRConfig(auth, opts),
        fetchSSRAuthor(auth, match as Match<{authorId: string}>, opts),
        fetchSSRPosts(auth, url, [PostType.ARTICLE, PostType.GUIDE], match, opts),
      ]);
    },
  },
  {
    path: '/404',
    exact: true,
    component: NotFoundScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<number> => {
      await fetchSSRConfig(auth, opts);
      return 404;
    },
  },
  {
    path: '*',
    exact: true,
    component: NotFoundScreen,
    loadData: async (
      auth: Auth, url: string, match: Match, opts: RequestInit = {},
    ): Promise<number> => {
      await fetchSSRConfig(auth, opts);
      return 404;
    },
  },
];

export default routes;
