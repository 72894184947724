import { TSliderItem } from '~/components/slider/types';

import image6 from '../images/image6.png';
import image7 from '../images/image7.png';
import image8 from '../images/image8.png';
import image9 from '../images/image9.png';

export const videosItems: TSliderItem[] = [
  {
    title: 'Роберт Сапольски',
    description: 'Знаменитый нейробиолог отвечает на вопросы подписчиков Reminder',
    imageSrc: image6,
    href: 'https://reminder.media/post/robert-sapolski-o-vazhnom-ot-voyny-do-psikhodelikov',
  },
  {
    title: 'Марина Мелия',
    description: 'Коуч и автор нескольких бестселлеров делится секретами счастья',
    imageSrc: image7,
    href: 'https://www.youtube.com/watch?v=Nlc-_SEUU74&t=1s',
    isVideo: true,
  },
  {
    title: 'Ярослав Ашихмин',
    description: 'Известный кардиолог и терапевт рассказывает о том, как достичь долголетия',
    imageSrc: image8,
    href: 'https://reminder.media/post/kak-sdelat-chekap',
  },
  {
    title: 'Алексей Молчанов',
    description: 'Рекордсмен мира по фридайвингу проводит практику для читателей Reminder',
    imageSrc: image9,
    href: 'https://www.youtube.com/watch?v=_xUZ1Nv-obw&t=10s',
    isVideo: true,
  },
];
